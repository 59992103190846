import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import {
  VariationProductControl,
  ProductControlOption,
  ProductControlMulti,
  ProductUnAvailability,
} from 'editor/src/store/variants/types';

import Accordion from 'editor/src/component/Accordion';
import MaxMessageInfo from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/MaxMessageInfo';
import { ControlOptionProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import { getUnAvailableMessage } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/utils';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import MultiSelectProductControlHeader from './MultiSelectProductControlHeader';

import styles from './MultiSelectProductControl.module.scss';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

export interface Props<P extends ProductControlOption> {
  options: P[];
  control: ProductControlMulti;
  selectedOptions: string[];
  availableOptions?: Set<string>;
  toggleOption(control: VariationProductControl, option: P): void;
  selectAll?(control: ProductControlMulti): void;
  deselectAll?(control: ProductControlMulti): void;
  Element: React.FC<ControlOptionProps<P>>;
  title: string;
  multiMode: boolean;
  maxCount?: number;
  maxMessage?: string;
  selectedCount?: number;
  className?: string;
  noTopBar?: boolean;
  IconElement?: React.FC;
  unAvailableOptions?: { [key: string]: ProductUnAvailability[] };
}

function MultiSelectProductControl<P extends ProductControlOption>({
  options,
  selectedOptions,
  availableOptions,
  toggleOption,
  selectAll,
  deselectAll,
  title,
  maxCount,
  maxMessage,
  Element,
  control,
  selectedCount = selectedOptions.length,
  className,
  multiMode,
  noTopBar,
  IconElement,
  unAvailableOptions,
}: Props<P>) {
  const [collapsed, setCollapsed] = useState(false);

  const onSelectAll = useCallback(
    (deselect = false) => {
      if (control.collapsible) {
        setCollapsed(false);
      }

      !deselect ? selectAll?.(control) : deselectAll?.(control);
    },
    [control, selectAll, deselectAll],
  );

  const optionsWithMeta = options.map((option) => {
    const selected = selectedOptions.includes(option.value);
    const unavailable = availableOptions && !availableOptions.has(option.value);
    const disabled =
      unavailable ||
      (multiMode && selected && selectedCount <= 1) ||
      (maxCount !== undefined && selectedCount >= maxCount && !selected);
    return {
      option,
      selected,
      unavailable,
      disabled,
    };
  });

  function sortDisabledOptionLast(
    { unavailable: unavailable1 }: (typeof optionsWithMeta)[0],
    { unavailable: unavailable2 }: (typeof optionsWithMeta)[0],
  ) {
    if (unavailable1) {
      return 1;
    }
    if (unavailable2) {
      return -1;
    }
    return 0;
  }

  const optionElements = (
    <div
      className={cn(
        styles.MultiSelectProductControl,
        className,
        'cy-multi-select-product-control',
        `cy-multi-select-product-control-${title.toLowerCase()}`,
      )}
    >
      {optionsWithMeta.sort(sortDisabledOptionLast).map(({ option, selected, disabled, unavailable }) => (
        <Element
          // we allow undefined as a value to support no-frame placeholder see https://github.com/gelatoas/gelato-api-dashboard/pull/4631
          key={option.value || ''}
          option={option}
          control={control}
          toggle={toggleOption}
          selected={selected}
          disabled={disabled}
          unavailable={unavailable ? getUnAvailableMessage(option.value, unAvailableOptions) : undefined}
        />
      ))}
    </div>
  );

  const header = (
    <MultiSelectProductControlHeader
      onSelectAll={onSelectAll}
      allItemsSelected={selectedOptions.length === options.length}
      allowSelectAll={!!control.allowSelectAll}
    >
      {title}
    </MultiSelectProductControlHeader>
  );

  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      {control.collapsible ? (
        <Accordion header={header} collapsed={collapsed} setCollapsed={setCollapsed}>
          {optionElements}
        </Accordion>
      ) : (
        <>
          <div className={controlStyles.controlTitleContainer}>
            {header}
            {IconElement && <IconElement />}
          </div>
          {optionElements}
        </>
      )}
      {maxMessage && maxCount !== undefined ? (
        <MaxMessageInfo optionCount={options.length} maxCount={maxCount} message={maxMessage} />
      ) : null}
    </>
  );
}

export default React.memo(MultiSelectProductControl);

import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import useOnClickOutside from 'editor/src/util/useOnClickOutside';

import IconCross from 'editor/src/component/Icon/IconCross';

import styles from './index.module.scss';

interface Props {
  rect: DOMRect | undefined;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

function Overlay({ title, rect, children, onClose }: Props) {
  const divRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(divRef, onClose);

  function onMouseDown(e: React.MouseEvent) {
    e.stopPropagation();
  }

  const positionRef = useRef({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handle = window.setTimeout(() => {
      const contentRect = divRef.current?.getBoundingClientRect();
      if (contentRect && rect) {
        const { clientHeight, clientWidth } = document.documentElement;
        const minY = clientHeight - contentRect.height - 20;
        const minX = clientWidth - contentRect.width - 20;
        positionRef.current.y = Math.min(rect.y, minY);
        positionRef.current.x = Math.min(rect.x - contentRect.width - 20, minX);
      }
      setVisible(true);
    }, 0);

    return () => window.clearTimeout(handle);
  }, []);

  return (
    <div
      style={{
        transform: `translate3d(${positionRef.current.x}px,${positionRef.current.y}px,0)`,
      }}
      className={cn(styles.Overlay, { [styles.hidden]: !visible })}
      ref={divRef}
      onMouseDown={onMouseDown}
    >
      <div className={styles.header}>
        {title}
        <div className={styles.close} onClick={onClose}>
          <IconCross />
        </div>
      </div>
      {children}
    </div>
  );
}

export default React.memo(Overlay);

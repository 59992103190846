import cn from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { addOrUpdateCalendarEventsAction } from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { getYearDatesFrame } from 'editor/src/util/dates/dateUtils';
import createCalendarEventId from 'editor/src/util/reflectDesignData/createCalendarEventId';

import Button from 'editor/src/component/Button';
import DatePicker from 'editor/src/component/DatePicker';
import IconCross from 'editor/src/component/Icon/IconCross';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import Input from 'editor/src/component/Input';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';

import styles from './index.module.scss';

type Props = {
  closeForm: () => void;
  event?: CalendarEvent;
};

const minEventNameLength = 2;
const maxEventNameLength = 30;

function AddEditEventForm({ closeForm, event }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridElement = useSelector(getFirstGridElement);
  const [error, setError] = useState('');
  const [newEventName, setNewEventName] = useState(event?.label || '');
  const [newEventDate, setNewEventDate] = useState<Date | null>(
    event ? new Date(event.year, event.month, event.day) : null,
  );
  const { startDate: minDate, endDate: maxDate } = getYearDatesFrame(gridElement?.grid.gridDate);

  const changeEventNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    checkIfEventNameValid(newValue);
    setNewEventName(newValue);
  };

  const checkIfEventNameValid = (eventName: string) => {
    if (eventName.length < minEventNameLength || eventName.length > maxEventNameLength) {
      setError(t('calendar-event-name-length', { min: minEventNameLength, max: maxEventNameLength }));
      return false;
    }
    error && setError('');
    return true;
  };

  const onAddNewEventClick = () => {
    if (!newEventName || !newEventDate || !checkIfEventNameValid(newEventName)) {
      return;
    }

    const newCalendarEvent = {
      id: event?.id || createCalendarEventId(newEventName, newEventDate, true),
      day: newEventDate.getDate(),
      month: newEventDate.getMonth(),
      year: newEventDate.getFullYear(),
      label: newEventName,
      checked: event?.checked || true,
      custom: true,
    };
    dispatch(addOrUpdateCalendarEventsAction([newCalendarEvent]));
    closeForm();
  };

  return (
    <div className={cn('cy-add-edit-custom-event-form', { [styles.addEditEventFormBorders]: Boolean(event) })}>
      <div className={styles.closeEditMode}>
        <Button tertiary onClick={() => closeForm()} className={styles.closeEditFormBtn}>
          <IconCross />
        </Button>
      </div>
      <Input placeholder={t('Event name')} value={newEventName} onChange={changeEventNameHandler} type="string" />
      <div className={styles.newEventFormRow}>
        <DatePicker
          placeholderText={t('Event date')}
          selected={newEventDate}
          minDate={minDate}
          maxDate={maxDate}
          openToDate={minDate}
          onSelect={(date) => setNewEventDate(date)}
        />
        <Button
          primary
          stopPropagation
          onClick={onAddNewEventClick}
          disabled={!newEventName || !newEventDate || Boolean(error)}
        >
          {event ? (
            t('Save')
          ) : (
            <>
              <IconPlus /> {t('Add')}
            </>
          )}
        </Button>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
}

export default React.memo(AddEditEventForm);

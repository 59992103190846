import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignCalendarEvents from 'editor/src/store/design/selector/getDesignCalendarEvents';
import getFirstGridElement from 'editor/src/store/design/selector/getFirstGridElement';
import { addOrUpdateCalendarEventsAction } from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { getFormatedDate, isWithinInterval, getYearDatesFrame } from 'editor/src/util/dates/dateUtils';
import { browserLocaleToEditorLocale } from 'editor/src/util/locales';

import Checkbox from 'editor/src/component/Checkbox';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';

import styles from './index.module.scss';

function PublicHolidays() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const calendarEvents = useSelector((state) => getDesignCalendarEvents(state));
  const gridElement = useSelector((state) => getFirstGridElement(state)?.grid);
  const { startDate, endDate } = getYearDatesFrame(gridElement?.gridDate);

  const publicHolidays = useMemo(() => {
    return (
      calendarEvents?.filter(
        ({ custom, month, year, day }) => !custom && isWithinInterval(new Date(year, month, day), startDate, endDate),
      ) || []
    );
  }, [calendarEvents]);

  const isAllSelected = useMemo(() => {
    return publicHolidays.every(({ checked }) => checked);
  }, [publicHolidays]);

  const toggleEvent = (event: CalendarEvent) => {
    dispatch(
      addOrUpdateCalendarEventsAction([
        {
          ...event,
          checked: !event.checked,
        },
      ]),
    );
  };

  const toggleAllPublicEvents = () => {
    dispatch(addOrUpdateCalendarEventsAction(publicHolidays.map((e) => ({ ...e, checked: !isAllSelected }))));
  };

  return (
    <>
      <ContentBlock className={styles.calendarEventsContentBlock}>
        <Checkbox
          className="cy-select-all-events-btn"
          on={isAllSelected}
          onChange={toggleAllPublicEvents}
          label={t('Select all')}
        />
      </ContentBlock>
      <ContentBlock className={cn('cy-public-holidays-list', styles.calendarEventsContentBlock)}>
        {publicHolidays.map((event) => {
          const eventLabel = (
            <div className={styles.eventItemCheckboxLabel}>
              {`${i18n.t(event.label, { lng: browserLocaleToEditorLocale[gridElement?.locale ?? 'en'] })} `}
              <span>{`(${getFormatedDate(new Date(event.year, event.month, event.day))})`}</span>
            </div>
          );

          return (
            <Checkbox
              key={event.id}
              className={`cy-public-holiday-${event.label.replace(/\s/g, '-')}`}
              on={Boolean(event?.checked)}
              onChange={() => toggleEvent(event)}
              label={eventLabel}
            />
          );
        })}
      </ContentBlock>
    </>
  );
}

export default React.memo(PublicHolidays);

import { ECommerceProduct } from 'editor/src/store/variants/types';

function getCurrentProductVariantPrice(currentProduct: ECommerceProduct | undefined): {
  price: string | undefined;
  originalPrice: string | undefined;
} {
  return {
    price: currentProduct?.variants.find((variant) => variant.id === currentProduct.selectedVariantId)?.price,
    originalPrice: currentProduct?.variants.find((variant) => variant.id === currentProduct.selectedVariantId)
      ?.compareAtPrice,
  };
}
export default getCurrentProductVariantPrice;

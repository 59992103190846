import { GridDesign } from '@gelatoas/design-editor-calendar';

import { updateElementsOnSpreadsAction } from 'editor/src/store/design/slice';
import { MediaGridInfo } from 'editor/src/store/design/types';
import getSpreadGridElements, { ElementMap } from 'editor/src/store/design/util/getSpreadGridElements';
import { FontDefinition } from 'editor/src/store/fonts/types';
import type { Thunk } from 'editor/src/store/hooks';

const updateGridElementOnAllSpreadsOperation =
  (data: { gridDesigns: GridDesign[]; fonts: FontDefinition[]; override: Partial<MediaGridInfo> }): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    const { designData } = state.design;
    const { gridDesigns, fonts, override } = data;

    const gridMediaMapPerSpread: { [key: number]: ElementMap } = {};
    designData?.spreads.forEach((spread, index) => {
      gridMediaMapPerSpread[index] = getSpreadGridElements(
        spread,
        override,
        fonts,
        gridDesigns,
        i18n,
        designData?.calendar?.events,
      );
    });

    dispatch(updateElementsOnSpreadsAction({ gridMediaMapPerSpread }));
  };

export default updateGridElementOnAllSpreadsOperation;

import { GridDesign, generatorUtils, generateGridAsSVG } from '@gelatoas/design-editor-calendar';
import cloneDeep from 'lodash/cloneDeep';

import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Spread, MediaGridInfo, MediaElement, CalendarEvent } from 'editor/src/store/design/types';
import { LayoutGeneratedTextType } from 'editor/src/store/editorModules/layouts/types';
import { FontDefinition } from 'editor/src/store/fonts/types';

import createGridHash from 'editor/src/util/design/createGridHash';
import getSpreadDate from 'editor/src/util/getSpreadDate';
import {
  getGeneratedMonthText,
  applyTextPropertiesToTextElement,
  getGeneratedYearText,
} from 'editor/src/util/layouts/createGeneratedTextMediaElement';
import { getCalendarEventsPerMonth } from 'editor/src/util/layouts/getCalendarEventsPerMonth';
import getGridDefaults from 'editor/src/util/layouts/getGridDefault';

import updateTextElementWithoutRender from 'editor/src/component/EditorArea/Spread/Page/MediaElement/Text/updateTextElementWithoutRender';

import isMediaGrid from './isMediaGrid';

import type { i18n } from 'i18next';

export interface ElementMap {
  [key: number]: MediaElement;
}

function getSpreadGridElements(
  spread: Spread,
  override: Partial<MediaGridInfo>,
  fonts: FontDefinition[],
  gridDesigns: GridDesign[],
  i18n: i18n,
  calendarEvents: CalendarEvent[] | undefined,
) {
  const gridElement = spread?.pages[0].groups.media?.find(isMediaGrid);
  const gridDefaults = getGridDefaults(i18n, spread.name);

  const spreadDate =
    (override?.gridDate ? getSpreadDate(spread.name, override.gridDate) : undefined) ??
    gridElement?.grid.gridDate ??
    gridDefaults.gridDate;

  const gridInfo: MediaGridInfo = {
    ...gridDefaults,
    ...gridElement?.grid,
    ...override,
    gridDate: spreadDate,
  };

  const gridDesign = gridDesigns.find((design) => design.name === gridInfo.designName) ?? gridDesigns[0];
  gridInfo.designName = gridDesign.name;

  const spreadWidth = getSpreadWidthFromSpread(spread);
  const d2f = generatorUtils.getToSizeFn(gridDesign, spreadWidth);

  const elementMap: ElementMap = {};
  const page = spread.pages[0];

  page.groups.media?.forEach((mediaElement) => {
    const media = cloneDeep(mediaElement);

    if (media.type === 'grid') {
      const monthEvents = getCalendarEventsPerMonth(gridInfo.gridDate, gridInfo.locale, calendarEvents);
      media.content = generateGridAsSVG(
        gridDesign,
        spreadDate,
        gridInfo.locale,
        media.width,
        media.height,
        monthEvents,
        {
          d2f,
          firstDayOfWeek: gridInfo.firstDayOfWeek,
          hideWeekNum: gridInfo.hideWeekNum,
        },
      );
      media.grid = gridInfo;
      media.gridHash = createGridHash(gridInfo, monthEvents);
    } else if (media.type === 'text' && media.generatedType) {
      switch (media.generatedType) {
        case LayoutGeneratedTextType.MONTH: {
          media.extra.text = getGeneratedMonthText(gridInfo, gridDesign.month.type);
          applyTextPropertiesToTextElement(media, gridDesign.month, fonts, d2f);
          updateTextElementWithoutRender(media, page);
          break;
        }
        case LayoutGeneratedTextType.YEAR: {
          media.extra.text = getGeneratedYearText(gridInfo);
          applyTextPropertiesToTextElement(media, gridDesign.year, fonts, d2f);
          updateTextElementWithoutRender(media, page);
          break;
        }
        default:
          break;
      }
    }
    elementMap[media.uuid] = media;
  });

  return elementMap;
}

export default getSpreadGridElements;

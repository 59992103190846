import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import { PrintTechnology } from 'editor/src/store/design/types';
import { useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';

import { RootState } from 'editor/src/store';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import DigitizationGuidelines from 'editor/src/component/DesktopSidebar/TabContents/GalleryTabContent/DigitizationGuidelines';
import DesignOptions from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/DesignOptions';
import ProductInfo from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductInfo';
import ProductIntro from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductIntro';
import TabContentLoadingBlocks from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/TabContentLoadingBlocks';
import UnavailableVariantsInfo from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/UnavailableVariantsInfo';
import IconInfo from 'editor/src/component/Icon/IconInfo';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import TabContentHeader from '../../TabContentHeader';

import ECommerceProductContent from './ECommerceProductContent';
import ErrorMessage from './ErrorMessage';
import ManualControls, { hasManualControls } from './ManualControls';
import ProductControlList from './ProductControlList';
import PageCountControl from './ProductControls/PageCountControl';

import styles from './index.module.scss';

interface Props {
  openInfo(): void;
  closeMenuCb?(): void;
}

function selector(state: RootState) {
  const { selectedPageCount, product, isLoaded, isVariantFlow, configuration, variationGroups, selectedMultiOptions } =
    state.variants;

  return {
    selectedPageCount,
    product,
    isLoaded,
    isVariantFlow,
    multiMode: !configuration.singleSelection,
    variationGroups,
    options: selectedMultiOptions,
    hasControls:
      hasManualControls(state) || !!(product.productControls.length + product.externalProductControls.length),
  };
}

function ProductControlContent({ openInfo, closeMenuCb }: Props) {
  const { t } = useTranslation();
  const { selectedPageCount, product, isLoaded, isVariantFlow, multiMode, options, hasControls } = useSelector(
    selector,
    shallowEqual,
  );
  const isMobile = useIsMobile();
  const { pages, productControls, externalProductControls, confirmOnControlChange } = product;
  const canShowControlList = !pages || selectedPageCount !== UNSELECTED_PAGE_COUNT;
  const showUnavailableVariants = useSelector((state) => state.variants.configuration.showUnavailableVariants);
  const requiresDigization = useSelector(
    (state) =>
      state.design.designData?.spreads[state.editor.currentSpreadIndex]?.technology === PrintTechnology.Embroidery,
  );
  const digitizationRules = useSelector((state) => getHostSetting(state, 'digitizationRules'));
  const showDesignOptions = useSelector((state) => getHostSetting(state, 'designOptions'));

  const variationGroups = useSelector((state) => state.variants.variationGroups);
  const selectedVariantsAmount = useMemo(
    () => variationGroups.reduce((acc, group) => acc + group.variationsInfo.length, 0) || 0,
    [variationGroups],
  );

  const productVariantsLimit = useSelector((state) => getHostSetting(state, 'variantsLimit'));

  const isLimitOfVariantsExceeded = useMemo(() => {
    if (!productVariantsLimit) {
      return false;
    }
    return selectedVariantsAmount > productVariantsLimit;
  }, [selectedVariantsAmount, productVariantsLimit]);

  const infoButton = useMemo(() => {
    if (hasControls) {
      return (
        <div onClick={openInfo} className={cn(styles.iconInfoContainer, 'cy-product-info-btn')}>
          <IconInfo className={styles.iconInfo} />
        </div>
      );
    }
    return null;
  }, [hasControls, openInfo]);

  return (
    <>
      <ContentBlock noBorder>
        {isMobile ? (
          <DrawerHeader title={t('editor-product')} closeMenu={closeMenuCb}>
            {infoButton}
          </DrawerHeader>
        ) : (
          <TabContentHeader title={t('editor-product')}>{infoButton}</TabContentHeader>
        )}
      </ContentBlock>
      {isLoaded || !isVariantFlow ? (
        <ContentBlock fill scroll className="cy-product-properties">
          {hasControls ? (
            <>
              <ProductIntro title={product.title} categoryTitle={product.categoryTitle} />
              {requiresDigization && digitizationRules?.guidelinesInfo && digitizationRules?.priceInfo && (
                <DigitizationGuidelines
                  guidelinesInfo={digitizationRules.guidelinesInfo}
                  priceInfo={digitizationRules?.priceInfo}
                />
              )}
              <ManualControls />
              {pages && <PageCountControl selectedPageCount={selectedPageCount} pages={pages} />}
              {canShowControlList && (
                <ProductControlList
                  multiMode={multiMode}
                  productControls={productControls}
                  externalProductControls={externalProductControls}
                  controlsUIOrder={product.controlsUIOrder}
                  confirmOnControlChange={confirmOnControlChange}
                />
              )}
              {showDesignOptions && multiMode && <DesignOptions />}
              {showUnavailableVariants && <UnavailableVariantsInfo product={product} options={options} />}

              {isLimitOfVariantsExceeded && (
                <ErrorMessage
                  selectedVariantsAmount={selectedVariantsAmount}
                  productVariantsLimit={productVariantsLimit}
                />
              )}
            </>
          ) : (
            <>
              {product.title && (
                <ProductInfo
                  productTitle={product.title}
                  productDescription={product.description}
                  categoryTitle={product.categoryTitle}
                  previewUrls={product.previewUrls}
                />
              )}
              <ManualControls noTopBar />
            </>
          )}
          <ECommerceProductContent />
        </ContentBlock>
      ) : (
        <TabContentLoadingBlocks />
      )}
    </>
  );
}

export default React.memo(ProductControlContent);

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'editor/src/store/hooks';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconGrid from 'editor/src/component/Icon/IconGrid';
import TabBar from 'editor/src/component/TabBar';

import CustomEvents from './CustomEvents';
import PublicHolidays from './PublicHolidays';

import styles from './index.module.scss';

function CalendarEventsTabContent() {
  const { t } = useTranslation();
  const enableCalendarEvents = useSelector((state) => state.hostSettings.enableCalendarEvents);

  const calendarEventsTabs: { key: 'holidays' | 'your-events'; label: string }[] = [
    {
      key: 'holidays',
      label: t('calendar-holidays'),
    },
    {
      key: 'your-events',
      label: t('calendar-custom-events'),
    },
  ];
  const [activeTab, setActiveTab] = useState(calendarEventsTabs[0].key);

  if (!enableCalendarEvents) {
    return null;
  }

  return (
    <>
      <TabContentHeader className={styles.calendarEventsHeader} title={t('calendar-events')} icon={<IconGrid />} />
      <ContentBlock scroll className={styles.calendarEventsTabContent}>
        <TabBar<'holidays' | 'your-events'> tabs={calendarEventsTabs} onSelect={setActiveTab} activeTab={activeTab} />
        {activeTab === calendarEventsTabs[0].key ? <PublicHolidays /> : <CustomEvents />}
      </ContentBlock>
    </>
  );
}

export default React.memo(CalendarEventsTabContent);

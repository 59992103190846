import React, { useEffect } from 'react';

import { setDesignSavingStatusAction } from 'editor/src/store/design/slice';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import { CanShow } from '../Menu/type';

import Status from './Status';

export const canShow: CanShow = (state, { hostSettings }) => !!hostSettings.editorMode;

function DesignSavingStatus() {
  const dispatch = useDispatch();
  const designSavingStatus = useSelector((state) => state.design.designSavingStatus);
  const editorMode = useSelector((state) => getHostSetting(state, 'editorMode'));

  useEffect(() => {
    function handleOnline() {
      dispatch(setDesignSavingStatusAction('saved'));
    }

    function handleOffline() {
      dispatch(setDesignSavingStatusAction('offline'));
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return editorMode ? <Status status={designSavingStatus} /> : null;
}
export default DesignSavingStatus;

import { useMemo } from 'react';

import {
  SingleOptions,
  ProductVariation,
  VariationProductControl,
  ProductControlOption,
} from 'editor/src/store/variants/types';

export function getExistingOptions<P extends ProductControlOption>(
  controlKey: string,
  options: P[],
  singleOptions: SingleOptions,
  variations: ProductVariation[],
): P[] {
  const singleOptionKeys = Object.keys(singleOptions);
  return options.filter((option) =>
    variations.some(
      (v) => v[controlKey] === option.value && singleOptionKeys.every((key) => v[key] === singleOptions[key]),
    ),
  );
}

function useFilteredOptionsWithVariations(
  control: VariationProductControl,
  variations: ProductVariation[],
  singleOptions: SingleOptions,
) {
  return useMemo(
    () => getExistingOptions(control.key, control.options, singleOptions, variations),
    [singleOptions, control],
  );
}

export default useFilteredOptionsWithVariations;

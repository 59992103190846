import cn from 'classnames';
import React from 'react';

import { Scene, SceneCategory } from 'editor/src/store/mockup/types';

import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import SceneItem from 'editor/src/component/DesktopSidebar/TabContents/SceneTabContent/SceneItem';

import styles from './index.module.scss';

interface Props {
  category: SceneCategory;
  selectedSceneId: string | undefined;
  onSceneSelect: (scene: Scene) => void;
}

function SceneList({ category, selectedSceneId, onSceneSelect }: Props) {
  return (
    <ContentBlock scroll className={cn(styles.SceneList, 'pt-2')}>
      {category.scenes.map((scene) => (
        <SceneItem key={scene.id} selected={selectedSceneId === scene.id} scene={scene} onSelect={onSceneSelect} />
      ))}
    </ContentBlock>
  );
}

export default React.memo(SceneList);

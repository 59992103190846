import React, { useMemo } from 'react';
import Select, { SingleValue } from 'react-select';

import { ProductControlOption } from 'editor/src/store/variants/types';

import { SingleProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import PropertySeparator from 'editor/src/component/DesktopSidebar/TabContents/PropertiesTabContent/PropertySeparator';

import controlStyles from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControl.module.scss';

export type Option = { value: number; label: string };

const GET_LABEL = (option: ProductControlOption) => option.title;

function DropDownControl({
  control,
  toggleOption,
  value,
  noTopBar,
  IconElement,
  availableOptions,
}: SingleProductControlProps) {
  const selectedOption = useMemo(
    () => control.options.find((option) => option.value === value),
    [value, control.options],
  );

  function onSelect(option: SingleValue<ProductControlOption>) {
    if (option) {
      toggleOption(control, option);
    }
  }

  function isOptionDisabled(option: ProductControlOption) {
    return !!availableOptions && !availableOptions.has(option.value);
  }

  return (
    <>
      {!noTopBar && <PropertySeparator bigMargin />}
      <div className={controlStyles.controlTitle}>{control.title}</div>
      {IconElement && <IconElement />}
      <Select
        placeholder={control.title}
        options={control.options}
        onChange={onSelect}
        value={selectedOption}
        getOptionLabel={GET_LABEL}
        isOptionDisabled={isOptionDisabled}
      />
    </>
  );
}

export default DropDownControl;

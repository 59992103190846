import cn from 'classnames';
import React from 'react';

import H1 from 'editor/src/component/Header/H1';
import ButtonClose from 'editor/src/component/SidebarMobile/ButtonClose';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

import styles from './index.module.scss';

interface Props {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  closeBtnClassName?: string;
}

function TabContentHeader({ icon, title, children, className, closeBtnClassName }: Props) {
  const isMobile = useIsMobile();
  return (
    <div className={cn(styles.TabContentHeader, className)}>
      {icon}
      <H1 noMargin noPadding className={styles.header}>
        {title}
      </H1>
      {children}
      {isMobile && (
        <div className={cn(styles.close, closeBtnClassName)}>
          <ButtonClose />
        </div>
      )}
    </div>
  );
}

export default React.memo(TabContentHeader);

import { LayoutSchema } from 'editor/src/store/editorModules/layouts/types';

function getAllowedLayouts(schemas: LayoutSchema[], spreadTags: string[] | undefined) {
  const spreadTagsSet = new Set(spreadTags);
  if (!spreadTagsSet.size) {
    return schemas.filter((layout) => !layout.tags || layout.tags.some((tag) => tag === '*'));
  }

  return schemas.filter((layout) => !layout.tags || layout.tags.some((tag) => spreadTagsSet.has(tag)));
}

export default getAllowedLayouts;

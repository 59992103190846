import React from 'react';
import { useTranslation } from 'react-i18next';

import { Scene, SceneCategory } from 'editor/src/store/mockup/types';

import CarouselContainer from 'editor/src/component/Carousel/CarouselContainer';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import SceneItem from 'editor/src/component/DesktopSidebar/TabContents/SceneTabContent/SceneItem';
import SceneList from 'editor/src/component/DesktopSidebar/TabContents/SceneTabContent/SceneList';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import IconScene from 'editor/src/component/Icon/IconScene';

interface Props {
  scenesCategories: SceneCategory[];
  selectedSceneId: string | undefined;
  openCategory: (categoryIndex: number) => void;
  onSceneSelect: (scene: Scene) => void;
}

function GroupedScenesContent({ scenesCategories, selectedSceneId, openCategory, onSceneSelect }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <TabContentHeader title={t('Scene')} icon={<IconScene />} />
      {scenesCategories.length === 1 ? (
        <SceneList category={scenesCategories[0]} onSceneSelect={onSceneSelect} selectedSceneId={selectedSceneId} />
      ) : (
        <ContentBlock scroll className="pt-2">
          {scenesCategories.map((category, index) => (
            <CarouselContainer
              key={index}
              title={category.name}
              showAll={category.scenes.length > 3 ? () => openCategory(index) : undefined}
            >
              {category.scenes.slice(0, 5).map((scene) => (
                <SceneItem
                  key={scene.id}
                  selected={selectedSceneId === scene.id}
                  scene={scene}
                  onSelect={onSceneSelect}
                />
              ))}
            </CarouselContainer>
          ))}
        </ContentBlock>
      )}
    </>
  );
}

export default React.memo(GroupedScenesContent);

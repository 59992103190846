import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import setPageBackgroundColorOperation from 'editor/src/store/design/operation/setPageBackgroundColorOperation';
import getPageBackgroundColor from 'editor/src/store/design/selector/getPageBackgroundColor';
import getCurrentSpreadIndex from 'editor/src/store/editor/selector/getCurrentSpreadIndex';
import { useDispatch, useSelector } from 'editor/src/store/hooks';
import getHostSetting from 'editor/src/store/hostSettings/selector/getHostSetting';

import usePagePropertyLiveUpdates from 'editor/src/util/usePageLiveUpdate';

import DrawerHeader from 'editor/src/component/BottomBarMobile/DrawerHeader';
import ColorPicker from 'editor/src/component/ColorPicker';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import TabContentHeader from 'editor/src/component/DesktopSidebar/TabContents/TabContentHeader';
import { useIsMobile } from 'editor/src/component/useDetectDeviceType';

interface Props {
  closeMenuCb?: () => void;
}

function PageBackgroundColorPickerTabContent({ closeMenuCb }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const currentSpreadIndex = useSelector(getCurrentSpreadIndex);
  const currentPageNumber = useSelector(
    (state) => state.design.designData?.spreads[currentSpreadIndex].pages[0].page_nr ?? 0,
  );
  const currentColor = useSelector((state) => getPageBackgroundColor(state, currentSpreadIndex));
  const colorPickerConfig = useSelector((state) => getHostSetting(state, 'colorPickerConfig'));

  const { liveUpdate } = usePagePropertyLiveUpdates(currentPageNumber, 'backgroundColor', currentColor);

  const onColorChange = useCallback(
    (color) => dispatch(setPageBackgroundColorOperation(currentSpreadIndex, undefined, color)),
    [colorPickerConfig.noColor, currentSpreadIndex],
  );

  return (
    <>
      {isMobile ? (
        <DrawerHeader title={t('editor-background-color')} closeMenu={closeMenuCb} />
      ) : (
        <TabContentHeader title={t('editor-background-color')} />
      )}
      <ContentBlock scroll>
        <ColorPicker
          color={currentColor}
          allowOpacity={colorPickerConfig.enableOpacity}
          colors={colorPickerConfig.allowedColors}
          allowNoColorLabel={t('No color')}
          allowNoColor
          onColorChange={onColorChange}
          onColorChanging={liveUpdate}
        />
      </ContentBlock>
    </>
  );
}

export default React.memo(PageBackgroundColorPickerTabContent);

import React from 'react';

import { MediaElement, StructureIndex } from 'editor/src/store/design/types';

import PersonalizationOptions from 'editor/src/component/TopMenuDesktop/buttons/ButtonPersonalization/PersonalizationOptions';

import styles from './index.module.scss';

interface Props {
  structureIndex: StructureIndex;
  selectedElement: MediaElement;
}

function TextAlignActionBar({ structureIndex, selectedElement }: Props) {
  return (
    <div className={styles.textAlignActionBar}>
      <PersonalizationOptions
        origin="text_align_action_bar"
        structureIndex={structureIndex}
        element={selectedElement}
      />
    </div>
  );
}

export default React.memo(TextAlignActionBar);

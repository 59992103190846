import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import addMediaElementOperation from 'editor/src/store/design/operation/addMediaElementOperation';
import removeAllMediaElementsFromPageOperation from 'editor/src/store/design/operation/removeAllMediaElementsFromPageOperation';
import getAddonElementsUsedInSpread from 'editor/src/store/design/selector/getAddonElementsUsedInSpread';
import getImageElementsUsedInSpread from 'editor/src/store/design/selector/getImageElementsUsedInSpread';
import getSpread from 'editor/src/store/design/selector/getSpread';
import getTextElementsUsedInSpread from 'editor/src/store/design/selector/getTextElementsUsedInSpread';
import { setSpreadLayoutSchemaNameAction } from 'editor/src/store/design/slice';
import { MediaElement } from 'editor/src/store/design/types';
import isRemoveElementsAllowed from 'editor/src/store/editor/selector/isRemoveElementsAllowed';
import { LayoutFrame, LayoutGroupInfo, LayoutSchema } from 'editor/src/store/editorModules/layouts/types';
import { GalleryImage } from 'editor/src/store/gallery/types';
import type { Thunk } from 'editor/src/store/hooks';

import getAppliedLayoutSchemaToSpreadMedia, {
  getImageWithDimensions,
} from 'editor/src/util/design/getAppliedLayoutSchemaToSpreadMedia';
import getLayoutBBox from 'editor/src/util/design/getLayoutBBox';
import getImageEltToImageInfo from 'editor/src/util/getImageEltToImageInfo';
import generateLayout from 'editor/src/util/layouts/generateLayout';
import generateLayoutFramesForGroupedSpread from 'editor/src/util/layouts/generateLayoutFramesForGroupedSpread';
import generateLayoutMediaElements from 'editor/src/util/layouts/generateLayoutMediaElements';
import notUndefined from 'editor/src/util/notUndefined';

const applyLayoutSchemaToSpreadOperation =
  (
    spreadIndex: number,
    layoutSchema?: LayoutSchema,
    placeImages?: GalleryImage[],
    layoutGroupInfo?: LayoutGroupInfo,
  ): Thunk =>
  (dispatch, getState, { i18n }) => {
    const state = getState();
    if (!state.design.designData) {
      return;
    }
    const spread = getSpread(state, spreadIndex);
    const schemaName = (layoutGroupInfo ? layoutGroupInfo.layoutGroup.name : layoutSchema?.name) ?? '';
    if (!spread) {
      return;
    }
    let layoutElements: MediaElement[] = [];
    let layoutFrames: LayoutFrame[] = [];
    if (layoutGroupInfo) {
      layoutFrames = generateLayoutFramesForGroupedSpread(
        spread,
        state.design.designData.spreads,
        layoutGroupInfo.spreadGroup,
        layoutGroupInfo.layoutGroup,
      );
    } else if (layoutSchema) {
      const bbox = spread ? getLayoutBBox(layoutSchema, spread) : undefined;
      if (!bbox?.width || !bbox?.height) {
        return;
      }
      layoutFrames = generateLayout(layoutSchema, bbox.left, bbox.top, bbox.width, bbox.height);
    } else {
      return;
    }

    layoutElements = generateLayoutMediaElements(
      state,
      spreadIndex,
      spread,
      layoutFrames,
      i18n,
      layoutGroupInfo?.layoutGroup?.tag,
    );

    const imageElementsToPlace = getImageElementsUsedInSpread(spread)
      .map((img) => getImageWithDimensions(state, img))
      .filter(notUndefined);
    const addonElementsToPlace = getAddonElementsUsedInSpread(spread);
    const textElementsToPlace = getTextElementsUsedInSpread(spread);
    const imagesToPlace = placeImages?.map((image) => getImageEltToImageInfo(image.id, image));

    batch(() => {
      dispatch(removeAllMediaElementsFromPageOperation({ spreadIndex, pageIndex: 0 }));

      const media = getAppliedLayoutSchemaToSpreadMedia(
        spread,
        layoutElements,
        {
          text: textElementsToPlace,
          image: imageElementsToPlace,
          addon: addonElementsToPlace,
        },
        isRemoveElementsAllowed(state),
        imagesToPlace,
      );

      media.forEach((element) => dispatch(addMediaElementOperation(spreadIndex, 0, element)));

      dispatch(
        setSpreadLayoutSchemaNameAction({
          spreadIndex,
          layoutSchemaName: schemaName,
        }),
      );
    });
  };

export default applyLayoutSchemaToSpreadOperation;

import { getSpreadHeightFromSpread } from 'editor/src/store/design/selector/getSpreadHeight';
import getSpreadWidthFromSpread from 'editor/src/store/design/selector/getSpreadWidthFromSpread';
import { Coords, DesignData, ElementAddress } from 'editor/src/store/design/types';
import { ExtendedWarning, WarningLevel, WarningType } from 'editor/src/store/editorModules/warnings/types';
import { RootState } from 'editor/src/store/index';
import shouldDigitizeSpread from 'editor/src/store/utils/shouldDigitizeSpread';

import { EMPTY_RGBA_STRING } from 'editor/src/util/color/rgbaUtils';
import getDpiLevelsFromDesign from 'editor/src/util/design/getDpiLevelsFromDesign';

import getDpiWarning from './getDpiWarning';
import getNotVisibleWarning, { isLineVisibleInSpread, isRectangleElementVisibleInSpread } from './getNotVisibleWarning';
import getOutOrRangeWarning from './getOutOrRangeWarning';

const calculateDesignDataWarnings = (designData: DesignData, state: RootState): ExtendedWarning[] => {
  const warnings: ExtendedWarning[] = [];
  const pageIndex = 0;
  const limits = getDpiLevelsFromDesign(designData);

  const { enabledWarnings, digitizationRules, ignorePersonalizationLock, editorMode } = state.hostSettings;
  const { images } = state.gallery;
  const isDesignEmpty = designData?.spreads.every((spread) => !spread.pages?.[0].groups.media?.length);

  designData?.spreads.forEach((spread, spreadIndex) => {
    const digitizeDesignElements = shouldDigitizeSpread(spread);
    const spreadWidth = getSpreadWidthFromSpread(spread);
    const spreadHeight = getSpreadHeightFromSpread(spread);
    const isPageEmpty = !spread.pages[0].groups.media?.length;
    const isImageBoxEmpty =
      spread.pages[0].groups.media?.length &&
      spread.pages[0].groups.media?.some((element) => element.type === 'image' && !element.imageId);

    // digitization
    const uniqueColors = new Set<string>([EMPTY_RGBA_STRING]);
    let stitchCount = 0;
    let isLoading = false;

    const currentSpreadGroup = designData?.spread_groups?.find((spreadGroup) =>
      spreadGroup.spreadIndexes.includes(spreadIndex),
    );
    spread.pages[0].groups.media?.forEach((element, elementIndex) => {
      if (element.personalizationLocked && !ignorePersonalizationLock) {
        return;
      }

      const showTextSampleWasNotReplaced = !!editorMode && element.type === 'text';

      const spreadCoords: Coords = {
        left: spread.x,
        top: spread.y,
      };

      // for grouped spreads, we need to check if the media element lies on the other spread in the group i.e. offset in the main spread.
      if (currentSpreadGroup) {
        if (currentSpreadGroup.spreadIndexes[0] !== spreadIndex) {
          // ignore other spread elements
          return;
        }

        let elementLiesInSpread: boolean;
        if (element.type === 'line') {
          elementLiesInSpread = isLineVisibleInSpread(element, spreadCoords, spreadWidth, spreadHeight);
        } else {
          elementLiesInSpread = isRectangleElementVisibleInSpread(element, spreadCoords, spreadWidth, spreadHeight);
        }
        if (!elementLiesInSpread) {
          spreadCoords.left = currentSpreadGroup?.position === 'horizontal' ? -spreadWidth - spread.x : spread.x;
          spreadCoords.top = currentSpreadGroup?.position === 'vertical' ? -spreadHeight - spread.y : spread.y;
        }
      }

      const elementAddress: ElementAddress = {
        spreadIndex,
        pageIndex,
        elementIndex,
      };
      if (element.type === 'image' && limits && element.imageId && enabledWarnings.LowDPI) {
        const image = images.find((img) => img.id === element.imageId);
        const dpiWarning = image && getDpiWarning(element, spreadIndex, image, limits);
        if (dpiWarning) {
          warnings.push({ ...dpiWarning, elementAddress });
        }
      }

      if (
        (element.type === 'image' || showTextSampleWasNotReplaced) &&
        element.sample &&
        !element.hidden &&
        enabledWarnings.SampleElementNotReplaced
      ) {
        warnings.push({
          type: WarningType.SampleElementNotReplaced,
          uuid: element.uuid,
          spreadIndex,
          level: WarningLevel.High,
          elementAddress,
        });
      }

      const outOfRangeWarning =
        enabledWarnings.OutOfRange && getOutOrRangeWarning(element, spreadIndex, spreadWidth, spreadHeight);
      if (outOfRangeWarning) {
        warnings.push({ ...outOfRangeWarning, elementAddress });
      }

      const notVisibleWarning =
        enabledWarnings.NotVisible &&
        getNotVisibleWarning(element, spreadIndex, spreadCoords, spreadWidth, spreadHeight);
      if (notVisibleWarning) {
        warnings.push({ ...notVisibleWarning, elementAddress });
      }

      if (element.type === 'image' && element.imageId) {
        const image = state.gallery.images.find((img) => img.id === element.imageId);

        stitchCount += element.stitch_count || 0;
        const overrides: { [color: string]: string } = {};
        element.colorOverrides?.fill?.forEach((color) => {
          overrides[color.previous] = color.new;
        });
        image?.quantizedColors?.forEach((color) => {
          uniqueColors.add(overrides?.[color] ?? color);
        });

        if (isLoading || element.digitizing || element.uploading) {
          isLoading = true;
        }
      } else if (element.type === 'text') {
        uniqueColors.add(element.fill);
        if (element.extra.stroke) {
          uniqueColors.add(element.extra.stroke.color);
        }
      }
    });

    if (enabledWarnings.EmptyPage && isPageEmpty) {
      warnings.push({
        type: WarningType.EmptyPage,
        spreadIndex,
        level: WarningLevel.Medium,
        isProceedBlocked: false,
      });
    }

    if (enabledWarnings.EmptyImageBox && isImageBoxEmpty) {
      warnings.push({
        type: WarningType.EmptyImageBox,
        spreadIndex,
        level: WarningLevel.Medium,
        isProceedBlocked: false,
      });
    }

    if (digitizeDesignElements) {
      if (enabledWarnings.MaxStitchCount && stitchCount > digitizationRules.maxStitchCount) {
        warnings.push({
          type: WarningType.MaxStitchCount,
          spreadIndex,
          level: WarningLevel.High,
          count: stitchCount,
        });
      }

      // -1 to exclude EMPTY_RGBA (transparent) color
      if (enabledWarnings.MaxColorCount && uniqueColors.size - 1 > digitizationRules.maxColorsCount) {
        warnings.push({
          type: WarningType.MaxColorCount,
          spreadIndex,
          level: WarningLevel.High,
          count: uniqueColors.size - 1,
        });
      }
    }
  });

  if (enabledWarnings.EmptyDesign && isDesignEmpty) {
    warnings.push({
      type: WarningType.EmptyDesign,
      level: WarningLevel.Medium,
      isProceedBlocked: false,
    });
  }

  return warnings;
};

export default calculateDesignDataWarnings;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAB_NAMES } from 'editor/src/store/editorModules/sidebar/types';
import { useSelector } from 'editor/src/store/hooks';

import Tab from 'editor/src/component/DesktopSidebar/Tabs/Tab/index';
import IconCalendar from 'editor/src/component/Icon/IconCalendar';

function CalendarEventsTab() {
  const { t } = useTranslation();
  const enableCalendarEvents = useSelector((state) => state.hostSettings.enableCalendarEvents);

  if (!enableCalendarEvents) {
    return null;
  }
  return (
    <Tab
      key={TAB_NAMES.CALENDAR_EVENTS}
      name={TAB_NAMES.CALENDAR_EVENTS}
      title={t('calendar-events')}
      icon={<IconCalendar />}
    />
  );
}

export default React.memo(CalendarEventsTab);

import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getDesignCalendarEvents from 'editor/src/store/design/selector/getDesignCalendarEvents';
import { addOrUpdateCalendarEventsAction, removeCalendarEventAction } from 'editor/src/store/design/slice';
import { CalendarEvent } from 'editor/src/store/design/types';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import { getFormatedDate } from 'editor/src/util/dates/dateUtils';

import Button from 'editor/src/component/Button';
import Checkbox from 'editor/src/component/Checkbox';
import ContentBlock from 'editor/src/component/DesktopSidebar/TabContents/Elements/ContentBlock';
import IconBin from 'editor/src/component/Icon/IconBin';
import IconPen from 'editor/src/component/Icon/IconPen';
import IconPlus from 'editor/src/component/Icon/IconPlus';

import AddEditEventForm from '../AddEditEventForm';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import styles from './index.module.scss';

function CustomEvents() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarEvents = useSelector((state) => getDesignCalendarEvents(state));
  const [addEventMode, setAddEventMode] = useState(false);
  const [editModeEvent, setEditModeEvent] = useState<CalendarEvent | undefined>();

  const customEvents = useMemo(() => {
    return calendarEvents?.filter(({ custom }) => custom) || [];
  }, [calendarEvents]);

  const toggleEvent = (event: CalendarEvent) => {
    dispatch(
      addOrUpdateCalendarEventsAction([
        {
          ...event,
          checked: !event.checked,
        },
      ]),
    );
  };

  const deleteEvent = (e: Event, id: string) => {
    dispatch(removeCalendarEventAction({ id }));
    e.stopPropagation();
  };

  const editEvent = (e: Event, event: CalendarEvent) => {
    setEditModeEvent(event);
    setAddEventMode(false);
    e.stopPropagation();
  };

  const addEvent = (e: Event) => {
    setEditModeEvent(undefined);
    setAddEventMode(true);
    e.stopPropagation();
  };

  return (
    <ContentBlock className={styles.calendarEventsContentBlock}>
      {calendarEvents && !addEventMode ? (
        <Button primary stopPropagation onClick={addEvent} className="cy-create-custom-event">
          <IconPlus />
          {t('Add new event')}
        </Button>
      ) : (
        <AddEditEventForm closeForm={() => setAddEventMode(false)} />
      )}
      <ContentBlock className={styles.calendarEventsContentBlock}>
        {customEvents.map((event) => {
          const eventLabel = (
            <div className={styles.eventItemCheckboxLabel}>
              {`${t(event.label)} `}
              <span>{`(${getFormatedDate(new Date(event.year, event.month, event.day))})`}</span>
            </div>
          );
          return (
            <div key={event.id} className={styles.eventItemBlock}>
              {editModeEvent?.id !== event.id ? (
                <Checkbox
                  key={event.id}
                  on={event.checked}
                  className={cn('cy-custom-event-item', styles.eventItemCheckbox)}
                  onChange={() => toggleEvent(event)}
                  label={eventLabel}
                >
                  <div className={styles.eventActionBtnsBlock}>
                    <Button className={styles.actionButton} tertiary onClick={(e) => editEvent(e, event)}>
                      <IconPen />
                    </Button>
                    <Button className={styles.actionButton} tertiary onClick={(e) => deleteEvent(e, event.id)}>
                      <IconBin />
                    </Button>
                  </div>
                </Checkbox>
              ) : (
                <AddEditEventForm event={event} closeForm={() => setEditModeEvent(undefined)} />
              )}
            </div>
          );
        })}
      </ContentBlock>
    </ContentBlock>
  );
}

export default React.memo(CustomEvents);

import React from 'react';

import setSidebarActiveTabOperation from 'editor/src/store/editorModules/sidebar/operation/setSidebarActiveTabOperation';
import { useDispatch } from 'editor/src/store/hooks';

import ControlButton from 'editor/src/component/ControlButton';
import IconCross from 'editor/src/component/Icon/IconCross';

function ButtonClose() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setSidebarActiveTabOperation(-1));
  };

  return (
    <ControlButton onClick={handleClick} className="cy-tab-close">
      <IconCross />
    </ControlButton>
  );
}

export default ButtonClose;

import React from 'react';

import { SingleProductControlProps } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/ProductControlProps';
import SingleValueProductControl from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ProductControls/single/SingleValueProductControl';

function SingleControl({
  control,
  element,
  toggleOption,
  value,
  noTopBar,
  IconElement,
  availableOptions,
  unAvailableOptions,
}: SingleProductControlProps) {
  // if only one option
  if (control.options.length < 2) {
    return null;
  }

  return (
    <SingleValueProductControl
      title={control.title}
      control={control}
      options={control.options}
      availableOptions={availableOptions}
      toggleOption={toggleOption}
      selectedValue={value}
      Element={element}
      noTopBar={noTopBar}
      IconElement={IconElement}
      unAvailableOptions={unAvailableOptions}
    />
  );
}

export default React.memo(SingleControl);
